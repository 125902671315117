export default {
  html: `<div class="work-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">HTML</div>`,
  css: `<div class="work-item-tag" style="background: white; border: 1px solid #7C8594; color: #7C8594">CSS</div>`,
  javascript: `<div class="work-item-tag" style="background: #FFB800;">JavaScript</div>`,
  Shopify: `<div class="work-item-tag" style="background: #FFB800;">Shopify</div>`,
  CMS: `<div class="work-item-tag" style="background: #FFB800;">CMS</div>`,
  socketio: `<div class="work-item-tag" style="background: #21BAEB;">Socket.IO</div>`,
  React: `<div class="work-item-tag" style="background: #21BAEB;">ReactJS</div>`,
  NextJS: `<div class="work-item-tag" style="background: #21BAEB;">NextJS</div>`,
  TailwindCSS: `<div class="work-item-tag" style="background: #CA49F8;">Tailwind CSS</div>`,
  StyledComponent: `<div class="work-item-tag" style="background: #CA49F8;">Styled Component</div>`,
  WebPack: `<div class="work-item-tag" style="background: #CA49F8;">Web Pack</div>`,
  Vercel: `<div class="work-item-tag" style="background: #CA49F8;">Vercel</div>`,
  webgl: `<div class="work-item-tag" style="background: #5A69EC;">WebGL</div>`,
  Php: `<div class="work-item-tag" style="background: #5A69EC;">Php</div>`,
  api: `<div class="work-item-tag" style="background: #CA49F8;">API</div>`,
  backend: `<div class="work-item-tag" style="background: #8433CC;">Backend</div>`,
};
