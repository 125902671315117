export default [
    {
        name: 'WebGL',
        width: '60%',
    },
    {
        name: 'ReactJS',
        width: '65%',
    },
    {
        name: 'VueJS',
        width: '85%',
    },
    {
        name: 'Shopify',
        width: '90%'
    },
    {
        name: '3D Modelling',
        width: '60%',
    },
]