export default [
  {
    id: 0,
    name: "Sophos",
    description: "Clothing Brand and Shopify Store",
    image: "images/projects/join.jpg",
    tags: ["React", "Shopify", "Php", "CMS", "html"],
    liveview: "https://shop.sophos.com/",
    github: "https://github.com/#",
    alt: "Shopify Store",
  },
  {
    id: 1,
    name: "The Hero with 1,000 Faces",
    description: "React Portal Website for Social Interaction",
    image: "images/projects/pokedex.jpg",
    tags: ["React", "TailwindCSS", "api", "html", "css"],
    liveview: "https://britannica.com/",
    github: "https://github.com/#",
    alt: "React Pixel Perfect website",
  },
  {
    id: 2,
    name: "GALAXY BALLS",
    description: "Next.js Based Game Portal Landing Page",
    image: "images/projects/sharkie.jpg",
    tags: ["NextJS", "StyledComponent", "WebPack", "Vercel"],
    liveview: "https://www.galaxy-balls.com/en",
    github: "https://github.com/#",
    alt: "javascript based jump-and-run game portfolio project",
  },
  {
    id: 3,
    name: "Education Website",
    description: `Social Edutech Website`,
    image: "images/projects/portfolio.jpg",
    tags: ["React", "javascript", "html", "css"],
    liveview: "https://www.pearson.com/",
    alt: "Roman Build This project from design",
    bannerIcons: [
      { src: "images/cssda-icon.png", alt: "cssda icon site of the day" },
    ],
    github: "https://github.com/#",
  },
  {
    id: 4,
    name: "Jelly Battle",
    description:
      "Play as Jelly and fight against three other Jellys in a multiplayer free-for-all game",
    image: "images/projects/jelly-battle.jpg",
    tags: ["webgl", "javascript", "socketio", "html", "css"],
    twitter: "https://twitter.com/DavidHckh",
    alt: "work in progress portfolio project jelly battle strategy game flash multiplayer",
  },
];
